import React from "react"
import { css } from "@emotion/core"
import { Row, Col } from "react-bootstrap"

import { mq } from "../utils/media-queries"
import ExternalLink from "./ExternalLink"

const Resource = ({
  heading,
  subheading,
  description,
  url,
  img,
  idx,
  bulletOne,
  bulletTwo,
  bulletThree,
  bulletFour,
  urlOne,
  urlTwo,
  urlThree,
  urlFour,
  linkOne,
  linkTwo,
  linkThree,
  linkFour,
}) => {
  let color = "#7E3F97"

  let bulletColor = "#01aef1"

  return (
    /* Per client, don't show interstitial when resource is clicked - 8/26/19 */
    // <ExternalLink externalUrl={url}>
    <Row>
      <Col
        css={css`
          h3 {
            font-size: 27px;
            line-height: 30px;
          }
          ${[mq[2]]} {
            h3 {
              font-size: 17px;
              line-height: 20px;
            }
          }
        `}
      >
        <h3
          css={css`
            color: ${color};
          `}
        >
          {heading}
        </h3>
        <p
          css={css`
            /* color: ${color}; */
            font-size: 21px;
            line-height: 30px;
          `}
        >
          {subheading}
        </p>
        <Row>
          <Col
            lg={{ span: 9 }}
            xs={9}
            css={css`
              display: flex;
              justify-content: start;
            `}
          >
            <ul
              css={css`
                padding-left: 0;
                margin-left: 30px;
                li {
                  color: ${bulletColor};
                  a {
                    font-size: 23px;
                    line-height: 26px;
                    font-weight: bold;
                    color: ${bulletColor};
                    text-decoration: none;
                  }
                  p {
                    font-size: 18px;
                    line-height: 26px;
                    font-weight: normal;
                    max-width: 90%;
                    em {
                      max-width: 90%;
                      overflow-wrap: anywhere;
                    }
                    /* padding-right: 200px; */
                  }
                }

                ${[mq[2]]} {
                  li {
                    font-size: 17px;
                    line-height: 20px;
                    color: ${bulletColor};
                    p {
                      font-size: 17px;
                      line-height: 20px;
                    }
                  }
                }
                ${[mq[1]]} {
                  li {
                    font-size: 15px;
                    line-height: 18px;
                    color: ${bulletColor};
                    p {
                      font-size: 15px;
                      line-height: 18px;
                    }
                  }
                }
              `}
            >
              <li>
                <ExternalLink
                  externalUrl={linkOne}>
                  {bulletOne}
                </ExternalLink>
                <p>
                  <em>{urlOne}</em>
                </p>
              </li>
              {bulletTwo ? (
                <li>
                  <ExternalLink
                    externalUrl={linkTwo}>
                    {bulletTwo}
                  </ExternalLink>
                  <p>
                    <em>{urlTwo}</em>
                  </p>
                </li>
              ) : (
                <li style={{ visibility: "hidden" }}>
                  <ExternalLink
                    externalUrl={linkTwo}>
                    test
                  </ExternalLink>
                  <p>
                    <em>test</em>
                  </p>
                </li>
              )}
              {bulletThree ? (
                <li>
                  <ExternalLink
                    externalUrl={linkThree}>
                    {bulletThree}
                  </ExternalLink>
                  <p>
                    <em>{urlThree}</em>
                  </p>
                </li>
              ) : (
                <li style={{ visibility: "hidden" }}>
                  <ExternalLink
                    externalUrl={linkThree}>
                    test
                  </ExternalLink>
                  <p>
                    <em>test</em>
                  </p>
                </li>
              )}
              {bulletFour ? (
                <li>
                  <ExternalLink
                    externalUrl={linkFour}>
                    {bulletFour}
                  </ExternalLink>
                  <p>
                    <em>{urlFour}</em>
                  </p>
                </li>
              ) : (
                <li style={{ visibility: "hidden" }}>
                  <ExternalLink
                    externalUrl={linkFour}>
                    test
                  </ExternalLink>
                  <p>
                    <em>test</em>
                  </p>
                </li>
              )}
            </ul>
          </Col>
        </Row>
      </Col>
      <img
        loading='lazy'
        css={css`
              position: absolute;
              float: right;
              right: 30px;
              bottom: 30px;
              width: 120px;
              ${[mq[2]]} {
                width: 100px;
              }
            `}
        alt=""
        src={img}
      />
    </Row>

  )
}

Resource.propTypes = {}

export default Resource
