import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"

import { mq } from "../utils/media-queries"
import NewResource from "./NewResource"
import LearnAbout from "./about/LearnAbout"

const ResourceSection = ({ idx, title, subtitle, resources }) => {
  let sectionBorder
  //let questions
  let learn
  let modal

  if (idx < 3) {
    sectionBorder = (
      <Row>
        <Col
          css={css`
            background: linear-gradient(180deg, #ae58cf 0%, #7e3f97 100%);
            height: 12px;
            margin-bottom: 40px;
            ${[mq[2]]} {
              margin-bottom: 20px;
            }
          `}
        ></Col>
      </Row>
    )
  } else {
    sectionBorder = ""
  }

  if (idx === 4) {
    //questions = <QuestionsToAsk />
    learn = <LearnAbout />
  }

  return (
    <Container
      css={css`
        h2 {
          font-size: 30px;
          line-height: 34px;
          margin-bottom: 0;
          color: #69696a;
          font-weight: bold;
          text-transform: uppercase;
        }
        h3 {
          font-size: 24px;
          line-height: 28x;
          margin-bottom: 0;
          font-weight: bold;
        }

        ${[mq[2]]} {
          h2 {
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 10px;
          }
          h3 {
            font-size: 17px;
            line-height: 20px;
          }
          p {
            font-size: 16px;
            line-height: 18px;
          }
        }
      `}
    >
      <Row>
        {resources.map(
          (
            {
              heading,
              subheading,
              url,
              description,
              bulletOne,
              bulletTwo,
              bulletThree,
              bulletFour,
              urlOne,
              urlTwo,
              urlThree,
              urlFour,
              linkOne,
              linkTwo,
              linkThree,
              linkFour,
              img,
            },
            resourceIdx
          ) => (
            <Col
              key={`col ${heading}`}
              lg={6}
              css={css`
                background: linear-gradient(
                  90deg,
                  rgba(255, 255, 255, 0) 0%,
                  #dddddd 100%
                );
                padding-top: 15px;
                margin-bottom: 15px;
                ${[mq[2]]} {
                  margin-bottom: 20px;
                }
              `}
            >
              <NewResource
                idx={resourceIdx}
                key={`resource ${heading}`}
                heading={heading}
                subheading={subheading}
                url={url}
                description={description}
                img={img}
                bulletOne={bulletOne}
                bulletTwo={bulletTwo}
                bulletThree={bulletThree}
                bulletFour={bulletFour}
                urlOne={urlOne}
                urlTwo={urlTwo}
                urlThree={urlThree}
                urlFour={urlFour}
                linkOne={linkOne}
                linkTwo={linkTwo}
                linkThree={linkThree}
                linkFour={linkFour}
              />
            </Col>
          )
        )}
      </Row>
      {learn}
      {sectionBorder}
      {modal}
    </Container>
  )
}

ResourceSection.propTypes = {}

export default ResourceSection
