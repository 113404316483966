import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"

import Layout from "../components/Layout"
import NewResourceSection from "../components/NewResourceSection"
import pyramid from "../assets/images/resources/pyramid.png"
import ear from "../assets/images/resources/ear.png"
import arrowsSquare from "../assets/images/resources/arrows-square.png"
import others from "../assets/images/resources/others.png"
import care from "../assets/images/resources/care.png"
import papers from "../assets/images/resources/papers.png"
import heart from "../assets/images/resources/heart.png"
import dollarCircle from "../assets/images/resources/dollar-circle.png"
import dollarHands from "../assets/images/resources/dollar-hands.png"
import doctor from "../assets/images/resources/doctor.png"
import scale from "../assets/images/resources/scale.png"
import hands from "../assets/images/resources/hands.png"
import highFive from "../assets/images/resources/high-five.png"
import exclamation from "../assets/images/resources/exclamation.png"
import Logo from "../assets/images/header-logo.png"
import iphoneLogoBlue from '../assets/images/iphoneLogoBlue.png'

const resourcesSections = {
  fromDiagnosisToTreatment: {
    idx: 1,
    title: "From Diagnosis to Treatment",
    subtitle:
      "Following an Hodgkin lymphoma diagnosis, working with your doctor to figure out a treatment plan is essential.",
    resources: [
      {
        heading: "Hodgkin lymphoma: THE BASICS",
        subheading: "(LLS.org)",
        url: "https://www.lls.org/lymphoma/hodgkin-lymphoma",
        description:
          "Brief, easy-to-understand overview from The Leukemia & Lymphoma Society (LLS).",
        img: pyramid,
      },
      {
        heading: "A LISTENING EAR",
        subheading: "(CancerSupportCommunity.org)",
        url: "https://www.cancersupportcommunity.org/cancer-support-helpline",
        description:
          "Cancer Support Helpline®, with licensed counselors providing guidance, resources and support.",
        img: ear,
      },
      {
        heading: "SHARED EXPERIENCES",
        subheading: "(LLS.org)",
        url: "https://www.lls.org/support/peer-to-peer-support",
        description:
          "LLS peer support program matching patients and caregivers with those having similar cancer and treatment experiences.",
        img: arrowsSquare,
      },
      {
        heading: "OTHERS CAN RELATE",
        subheading: "(Lymphoma.org)",
        url: "https://www.lymphoma.org/learn/supportservices/lsn/",
        description:
          "LRF peer support network matching patients and caregivers with others sharing the same experiences.",
        img: others,
      },
      {
        heading: "TRANSPLANT RESOURCES",
        subheading: "(bmtinfonet.org)",
        url: "http://www.bmtinfonet.org",
        description:
          "Helpful videos, handbooks and information for patients before, during and after transplant.",
        img: heart,
      },
    ],
  },
  afterTreatment: {
    idx: 2,
    title: "After Treatment",
    subtitle: "Navigating a new normal – whatever outcome you face.",
    resources: [
      {
        heading: "FOLLOW UP CARE",
        subheading: "(LLS.org)",
        url:
          "https://www.lls.org/managing-your-cancer/follow-up-care-and-survivorship",
        description:
          "Importance of maintaining appointments and taking care of yourself, from The Leukemia & Lymphoma Society (LLS).",
        img: care,
      },
      {
        heading: "SURVIVORSHIP RESOURCES",
        subheading: "(Lymphoma.org)",
        url: "https://www.lymphoma.org/aboutlymphoma/hl/hlsurvivorship/",
        description:
          "Videos and downloadable resources from the Lymphoma Research Foundation (LRF) helping patients achieve quality of life.",
        img: papers,
      },
      {
        heading: "SUPPORT NEVER ENDS",
        subheading: "(CancerCare.org)",
        url: "https://www.cancercare.org/diagnosis/hodgkin_lymphoma",
        description:
          "Webinars and workshops that discuss fertility, sexuality, intimacy, communication, health maintenance and more.",
        img: heart,
      },
    ],
  },

  additionalResources: {
    idx: 3,
    title: "Additional Resources",
    subtitle: "Find financial, career and community resources.",
    resources: [
      {
        heading: "LLS FINANCIAL SUPPORT",
        subheading: "(LLS.org)",
        url: "https://www.lls.org/support/financial-support",
        description:
          "LLS financial assistance programs, including links to other public and private resources.",
        img: dollarCircle,
      },
      {
        heading: "LRF FINANCIAL SUPPORT",
        subheading: "(Lymphoma.org)",
        url: "https://www.lymphoma.org/learn/supportservices/financialsupport/",
        description:
          "LRF financial assistance program, with links to other support resources.",
        img: dollarHands,
      },
      {
        heading: "LISTEN TO THE EXPERTS",
        subheading: "(CancerCare.org)",
        url: "https://www.cancercare.org/diagnosis/hodgkin_lymphoma",
        description:
          "Timely information on healthcare costs and legal protections in the workplace (see “Connect Education Workshops”).",
        img: doctor,
      },
      {
        heading: "BALANCE WORK & CANCER",
        subheading: "(cancerandcareers.org)",
        url: "http://www.cancerandcareers.org",
        description:
          "Career coaching, resume review tools, and job search toolkits for those living and working with cancer.",
        img: scale,
      },
      {
        heading: "GET INVOLVED",
        subheading: "(LLS.org/joinlls)",
        url: "http://www.lls.org/joinlls",
        description:
          "Fun and creative ways to get involved, from Team in Training, to Light the Night Walk and more.",
        img: hands,
      },
      {
        heading: "YOUNG ADULTS, UNITE",
        subheading: "(stupidcancer.org)",
        url: "https://stupidcancer.org/events/cancercon/",
        description:
          "CancerCon is the annual gathering for the young adult cancer movement.",
        img: highFive,
      },
      {
        heading: "MAKE IT EPIC!",
        subheading: "(epicexperience.org)",
        url: "http://www.epicexperience.org",
        description:
          "Weeklong outdoor adventures with other cancer patients—from snowshoeing to whitewater rafting, and everything in between.",
        img: exclamation,
      },
    ],
  },

  newFromDiagnosisToTreatment: {
    idx: 4,
    resources: [
      {
        heading: "TREATMENT DECISIONS",
        subheading: "Learn about understanding your options and making Hodgkin lymphoma treatment decisions",
        bulletOne: "HODGKIN LYMPHOMA TREATMENT",
        urlOne: "(LLS.org)",
        linkOne: "https://www.lls.org/lymphoma/hodgkin-lymphoma/treatment",
        bulletTwo: "TREATING HODGKIN LYMPHOMA",
        urlTwo: "(Cancer.org)",
        linkTwo: "https://www.cancer.org/cancer/hodgkin-lymphoma/treating.html",
        bulletThree: "ABOUT HODGKIN LYMPHOMA",
        urlThree: "(Lymphoma.org)",
        linkThree: "https://lymphoma.org/aboutlymphoma/hl/",
        bulletFour: "MAKING DECISIONS ABOUT CANCER TREATMENT",
        urlFour: "(Cancer.net)",
        linkFour: "https://www.cancer.net/navigating-cancer-care/how-cancer-treated/making-decisions-about-cancer-treatment",
        img: care,
      },
      {
        heading: "TOOLS & APPS",
        subheading: "Use these helpful tools to help communicate with your care team and manage your care",
        bulletOne: "DOCTOR DISCUSSION GUIDE",
        urlOne: "(LLS.org)",
        linkOne: "https://www.lls.org/sites/default/files/National/USA/Pdf/Publications/questions_to_ask_about_HL_JUNE2017.pdf",
        bulletTwo: "FOCUS ON LYMPHOMA APP",
        urlTwo: "(Lymphoma.org)",
        linkTwo: "https://lymphoma.org/resources/educationresources/mobileapp",
        bulletThree: "HEALTH MANAGER APP",
        urlThree: "(LLS.org)",
        linkThree: "http://www.lls.org/HealthManager",
        bulletFour: "CANCER.NET MOBILE",
        urlFour: "(Cancer.net)",
        linkFour: "http://www.cancer.net/navigating-cancer-care/managing-your-care/cancernet-mobile",
        img: iphoneLogoBlue,
      },
      {
        heading: "FINANCIAL/CAREER SERVICES",
        subheading: "Find financial assistance programs and career support resources",
        bulletOne: "LLS FINANCIAL SUPPORT",
        urlOne: "(LLS.org)",
        linkOne: "https://www.lls.org/support/financial-support",
        bulletTwo: "LRF FINANCIAL SUPPORT",
        urlTwo: "(Lymphoma.org)",
        linkTwo: "https://www.lymphoma.org/learn/supportservices/financialsupport/",
        bulletThree: "CANCER.NET FINANCIAL CONSIDERATIONS",
        urlThree: "(Cancer.net)",
        linkThree: "https://www.cancer.net/navigating-cancer-care/financial-considerations",
        bulletFour: "BALANCE WORK & CANCER",
        urlFour: "(CancerAndCareers.org)",
        linkFour: "https://www.cancerandcareers.org/en",
        img: dollarHands,
      },
      {
        heading: "PEER SUPPORT",
        subheading: "Find support from peers and licensed counselors",
        bulletOne: "A LISTENING EAR",
        urlOne: "(CancerSupportCommunity.org)",
        linkOne: "https://www.cancersupportcommunity.org/cancer-support-helpline",
        bulletTwo: "SHARED EXPERIENCES",
        urlTwo: "(LLS.org)",
        linkTwo: "https://www.lls.org/support/peer-to-peer-support",
        bulletThree: "STUPID CANCER MEETUPS",
        urlThree: "(Stupidcancer.org)",
        linkThree: "https://stupidcancer.org/meetups/",
        img: others,
      }
    ],
  },
}

export default () => {
  const {
    fromDiagnosisToTreatment,
    afterTreatment,
    additionalResources,
    newFromDiagnosisToTreatment,
  } = resourcesSections
  return (
    <Layout
      title='Hodgkin Lymphoma Treatment Resources & Support Programs | Hodgkin Hub'
      description='Find Hodgkin Lymphoma community support, survivorship, and financial services from Lymphoma and cancer partners.'
      canonicalURL='https://hodgkinhub.com/resources/'
      OGTitle='Hodgkin Lymphoma Treatment Resources & Support Programs'
      OGDescription='Find Hodgkin Lymphoma community support, survivorship, and financial services from Lymphoma and cancer partners.'
      OGImage={Logo}
      OGUrl='https://hodgkinhub.com/resources/'
      OGType='Website'
    >
      <Container>
        <Row>
          <Col
            css={css`
              padding: 48px 15px;
            `}
          >
            <h1
              css={css`
                color: #8C8D91;
                font: bold 30px/45px Aria, sans-serif;
                text-align: center;
                padding-bottom: 0;
                margin-bottom: 0;
              `}
            >
              HODGKIN LYMPHOMA RESOURCES
            </h1>
            <h2 css={css`
                color: #8C8D91;
                font: 22px/25px Aria, sans-serif;
                text-align: center;
              `}>From Diagnosis Through Treatment Decisions</h2>
          </Col>
        </Row>
      </Container>
      {/* <ResourceSection
        idx={fromDiagnosisToTreatment.idx}
        title={fromDiagnosisToTreatment.title}
        subtitle={fromDiagnosisToTreatment.subtitle}
        resources={fromDiagnosisToTreatment.resources}
      /> */}
      <NewResourceSection
        idx={newFromDiagnosisToTreatment.idx}
        title={newFromDiagnosisToTreatment.title}
        subtitle={newFromDiagnosisToTreatment.subtitle}
        resources={newFromDiagnosisToTreatment.resources}
        bulletOne={newFromDiagnosisToTreatment.bulletOne}
        bulletTwo={newFromDiagnosisToTreatment.bulletTwo}
        bulletThree={newFromDiagnosisToTreatment.bulletThree}
        bulletFour={newFromDiagnosisToTreatment.bulletFour}
      />
      {/* <ResourceSection
        idx={afterTreatment.idx}
        title={afterTreatment.title}
        subtitle={afterTreatment.subtitle}
        resources={afterTreatment.resources}
      />
      <ResourceSection
        idx={additionalResources.idx}
        title={additionalResources.title}
        subtitle={additionalResources.subtitle}
        resources={additionalResources.resources}
      /> */}
    </Layout>
  )
}
